import { getEvents } from "../services/data.service";
import {
  Box,
  Breadcrumbs,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Masonry } from "@mui/lab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaceIcon from "@mui/icons-material/Place";
import ShareIcon from "@mui/icons-material/Share";
import MessageIcon from "@mui/icons-material/Message";
import { share } from "../services/share.service";
import { useNavigate, useParams } from "react-router";
import EventIcon from "@mui/icons-material/Event";
import DirectionsIcon from "@mui/icons-material/Directions";
import { mapsURL, messages } from "../vars";
import { NavLink } from "react-router-dom";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { eventId } = useParams();

  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    setLoading(true);
    getEvents().then((results) => {
      setEvents(results);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (eventId) {
      let event = events.find((a) => a.id === eventId);
      setSelectedEvent(event);
    } else {
      setSelectedEvent(null);
    }
  }, [events, eventId]);

  const shareEvent = (event, eventId) => {
    share("Te invito este evento de FOTONOBA!", event.title, eventId);
  };

  const moreInfoText = (event) => messages.eventInfo(event.title);

  return (
    <>
      {loading && <CircularProgress sx={{ mt: 2 }} color="primary" />}

      {selectedEvent ? (
        <>
          <Breadcrumbs sx={{ my: 2 }}>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => navigate("/events")}
            >
              <EventIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Eventos
            </Link>
            <Typography
              sx={{
                color: "text.primary",
                display: "flex",
                alignItems: "center",
              }}
            >
              {selectedEvent.title}
            </Typography>
          </Breadcrumbs>

          <Stack
            spacing={5}
            direction={{ xs: "column", md: "row" }}
            sx={{ alignContent: { xs: "center", md: "left" } }}
          >
            <img
              style={{
                width: "100%",
                maxHeight: "500px",
                "object-fit": "contain",
              }}
              src={selectedEvent.photo}
              title={selectedEvent.title}
              alt={selectedEvent.title}
            />
            <Box>
              <Chip
                color="primary"
                size="small"
                label={selectedEvent.type}
                gutterBottom
              />
              <Typography gutterBottom variant="h5">
                {selectedEvent.title}
              </Typography>
              <Typography gutterBottom variant="body2">
                {selectedEvent.description}
              </Typography>
              <List>
                {[
                  { icon: <CalendarMonthIcon />, label: selectedEvent.date },
                  { icon: <AccessTimeIcon />, label: selectedEvent.hour },
                  { icon: <PlaceIcon />, label: selectedEvent.address },
                ].map((item) => (
                  <ListItem disablePadding disableGutters>
                    <ListItemIcon sx={{ minWidth: "35px" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                ))}
              </List>

              <SpeedDial
                sx={{ position: "fixed", bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                ariaLabel="Event actions"
                FabProps={{
                  color: "secondary",
                }}
              >
                <SpeedDialAction
                  onClick={() =>
                    window.open(mapsURL + selectedEvent.address, "_blank")
                  }
                  icon={<DirectionsIcon />}
                  tooltipTitle="¿Cómo llegar?"
                  tooltipOpen
                />

                {selectedEvent.calendar && (
                  <SpeedDialAction
                    onClick={() =>
                      window.open(selectedEvent.calendar, "_blank")
                    }
                    icon={<EventIcon />}
                    tooltipTitle="Agregar al calendario"
                    tooltipOpen
                  />
                )}

                <SpeedDialAction
                  icon={<MessageIcon />}
                  tooltipTitle="Consultar"
                  tooltipOpen
                  onClick={() =>
                    navigate("/contact?message=" + moreInfoText(selectedEvent))
                  }
                />

                <SpeedDialAction
                  icon={<ShareIcon />}
                  tooltipTitle="Compartir"
                  tooltipOpen
                  onClick={() => shareEvent(selectedEvent)}
                />
              </SpeedDial>
            </Box>
          </Stack>
        </>
      ) : (
        <Box>
          <Masonry spacing={2} columns={{ xs: 1, sm: 2, md: 3 }}>
            {events.map((event) => (
              <Card variant="outlined">
                <CardActionArea
                  {...(!event.lapsed && {
                    onClick: () => navigate(`/events/${event.id}`),
                  })}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      ...(event.lapsed && { filter: "grayscale(100%)" }),
                    }}
                    src={event.photo}
                    title={event.title}
                    alt={event.title}
                  />
                  <CardContent>
                    <Chip
                      color="primary"
                      size="small"
                      label={event.type}
                      gutterBottom
                    />
                    <Typography gutterBottom variant="h5" component="div">
                      {event.title}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      {event.description}
                    </Typography>
                    <List>
                      {[
                        { icon: <CalendarMonthIcon />, label: event.date },
                        { icon: <AccessTimeIcon />, label: event.hour },
                        { icon: <PlaceIcon />, label: event.address },
                      ].map((item) => (
                        <ListItem disablePadding disableGutters>
                          <ListItemIcon sx={{ minWidth: "35px" }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText primary={item.label} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </CardActionArea>
                {!event.lapsed && (
                  <>
                    <Divider />
                    <CardActions spacing={2} sx={{ justifyContent: "end" }}>
                      <IconButton
                        component="a"
                        href={mapsURL + event.address}
                        target="_blank"
                      >
                        <DirectionsIcon />
                      </IconButton>
                      {event.calendar && (
                        <IconButton
                          component="a"
                          href={event.calendar}
                          target="_blank"
                        >
                          <EventIcon />
                        </IconButton>
                      )}
                      <IconButton
                        component={NavLink}
                        to={"/contact?message=" + moreInfoText(event)}
                      >
                        <MessageIcon />
                      </IconButton>
                      <IconButton onClick={() => shareEvent(event, event.id)}>
                        <ShareIcon />
                      </IconButton>
                    </CardActions>
                  </>
                )}
              </Card>
            ))}
          </Masonry>
        </Box>
      )}
    </>
  );
};

export default Events;
