import { Box, Stack } from "@mui/material";
import isologo from "../assets/isologo.png";
import SocialMedia from "./SocialMedia";

const Footer = () => {
  return (
    <>
      <Box
        sx={{
          px: 10,
          pt: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={isologo} alt="fotonoba isologo" />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          py: 2,
          justifyContent: "center",
        }}
      >
        <SocialMedia />
      </Stack>
    </>
  );
};
export default Footer;
