import { Button, Drawer, IconButton, Stack, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import "../assets/fonts/fonts.css";
import SocialMedia from "./SocialMedia";

const buttonsProps = {
  component: Link,
  size: "small",
  variant: "contained",
};

const MenuList = ({ customProps }) => {
  return (
    <>
      <Button {...buttonsProps} to="/gallery" color="fucsia" {...customProps}>
        Galería
      </Button>
      <Button {...buttonsProps} to="/events" color="amarillo" {...customProps}>
        Eventos
      </Button>
      <Button {...buttonsProps} to="/about" color="celeste" {...customProps}>
        Nosotros
      </Button>
      <Button {...buttonsProps} to="/contact" color="lila" {...customProps}>
        Contacto
      </Button>
    </>
  );
};

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Toolbar component="header" sx={{ py: 1 }}>
        <Stack
          direction={"row"}
          spacing={2}
          sx={{ display: { xs: "none", md: "flex" }, width: "30%" }}
        >
          <MenuList />
        </Stack>

        <Stack sx={{ width: { xs: "100%", md: "40%" }, px: 2 }}>
          <Typography
            variant="h4"
            align="center"
            sx={{ fontFamily: "Futuris", fontWeight: "bold" }}
            color="primary"
          >
            FOTONOBA
          </Typography>
          <Typography
            variant="caption"
            align="center"
            sx={{ fontFamily: "Pilcrow" }}
          >
            FOTÓGRAFOS DEL NOROESTE DE LA PCIA. DE BUENOS AIRES
          </Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            display: { xs: "none", md: "flex" },
            width: "30%",
            justifyContent: "end",
          }}
        >
          <SocialMedia />
        </Stack>

        <IconButton
          onClick={() => setOpen(true)}
          sx={{
            display: { xs: "flex", md: "none" },
            position: "absolute",
            right: "0",
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{ onClick: () => setOpen(false) }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ mt: 5 }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Stack sx={{ mt: 10, mx: 10, flexGrow: 1 }} spacing={5}>
            <MenuList customProps={{ color: "inherit", variant: "text" }} />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              my: 5,
              justifyContent: "center",
            }}
          >
            <SocialMedia />
          </Stack>
        </Drawer>
      </Toolbar>

      <Stack
        direction={"row"}
        spacing={1}
        sx={{ display: { xs: "flex", md: "none" }, justifyContent: "center" }}
      >
        <MenuList />
      </Stack>
    </>
  );
};

export default Header;
