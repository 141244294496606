const Photo = (props) => {
  return {
    full: props.completa,
    thumbnail: props.miniatura,
    alt: props.descripcion,
    author: props.autor,
  };
};

export default Photo;
