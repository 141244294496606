const Author = (props) => {
  return {
    id: props.id,
    name: props.nombre,
    profile: props.foto,
    email: props.email,
    facebook: props.facebook,
    instagram: props.instagram,
    whatsapp: props.whatsapp,
    web: props.web,
  };
};

export default Author;
