import { Box, Stack, Typography } from "@mui/material";
import image1 from "../assets/about-us/image1.webp";
import image2 from "../assets/about-us/image2.webp";
import image3 from "../assets/about-us/image3.webp";
import image4 from "../assets/about-us/image4.webp";
import image5 from "../assets/about-us/image5.webp";
import image6 from "../assets/about-us/image6.webp";
import image7 from "../assets/about-us/image7.webp";
import image8 from "../assets/about-us/image8.webp";

import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const photos = [image1, image2, image3, image4, image5, image6, image7, image8];

const AboutUs = () => {
  return (
    <>
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            background:
              "linear-gradient(180deg, rgba(0,0,0,0.5634628851540616) 0%, rgba(255,255,255,0) 90%)",
            zIndex: 9999,
            position: "absolute",
            top: 0,
            left: 0,
            color: "white",
            width: "100%",
            p: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontFamily: "Futuris", fontWeight: "bold" }}
          >
            ESTO ES FOTONOBA
          </Typography>
        </Box>

        <Fade pauseOnHover={false}>
          {photos.map((p, i) => (
            <Box key={`photo-${i}`}>
              <img
                src={p}
                alt=""
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  width: "auto",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </Box>
          ))}
        </Fade>
      </Box>

      <Stack
        spacing={5}
        sx={{
          mt: 2,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
        direction={{ sx: "column", sm: "row" }}
      >
        <Stack spacing={2}>
          <Typography variant="h6">
            Somos un grupo abierto. Hacemos fotografía recreativa.
          </Typography>

          <Typography variant="h6">
            Somos apasionados. Nos encanta descubrir la belleza de lo cotidiano,
            en los paisajes de la llanura, las calles de la ciudad, un simple
            atardecer o una noche estrellada.
          </Typography>

          <Typography variant="h6">
            Cuando tenemos la oportunidad, exponemos nuestro arte, con la
            intención de compartir con otros, lo que capturamos con nuestros
            lentes.
          </Typography>

          <Typography variant="h6">
            Aprendemos en grupo, la pasamos bien!
          </Typography>
        </Stack>

        <iframe
          width="300"
          height="533"
          src="https://www.youtube.com/embed/awlU71zHcV0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{ width: "100%", height: "100%", aspectRatio: "9 / 16" }}
        ></iframe>
      </Stack>
    </>
  );
};

export default AboutUs;
