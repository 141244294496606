import { contacts, whatsappURL } from "../vars";

export const send = (name, text, contact) => {
  let message = "Hola, mi nombre es " + name + ". " + text;

  let number = contacts[contact].tel;

  let url = `${whatsappURL}?phone=${number}&text=${message}`;

  window.open(url, "_blank");
};

export const generateWhatsappURL = (number) => `${whatsappURL}?phone=${number}`;
