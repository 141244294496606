import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const Event = (props) => {
  return {
    id: props.id,
    title: props.titulo,
    type: props.tipo,
    date: props.fecha,
    hour: props.hora,
    address: props.direccion,
    description: props.descripcion,
    photo: props.foto,
    calendar: props.calendario,
    lapsed: dayjs().isAfter(dayjs(props.fecha, "D/MM/YYYY")),
  };
};

export default Event;
