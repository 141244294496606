import {
  Container,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import AboutUs from "./components/AboutUs";
import Events from "./components/Events";
import { Navigate, Route, Routes } from "react-router-dom";
import Contact from "./components/Contact";
import ScrollButton from "./components/ScrollButton";

//import "./assets/bodyBorder.css";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#3ab6fe",
    },
    secondary: {
      main: "#ee217e",
    },
    fucsia: { main: "#ee217e", contrastText: "#FFFFFF" },
    amarillo: { main: "#ffff01", contrastText: "#112A46" },
    celeste: { main: "#3ab6fe", contrastText: "#FFFFFF" },
    lila: { main: "#c768e0", contrastText: "#FFFFFF" },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#c768e0",
          color: "white",
        },
        colorSecondary: {
          backgroundColor: "#ffff01",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: "bottomBorder" },
              style: {
                borderBottom: "2px solid",
                borderRadius: "0",
              },
            },
          ],
        },
      },
    },
  },
});

const ContainerCentered = ({ children }) => (
  <Container
    component="main"
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      py: 2,
    }}
  >
    {children}
  </Container>
);

const GalleryPage = () => {
  return (
    <Container
      component="main"
      maxWidth="100%"
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Gallery />
    </Container>
  );
};

const EventsPage = () => {
  return (
    <ContainerCentered>
      <Events />
    </ContainerCentered>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header />

      <ScrollButton />

      <Routes>
        <Route path="/">
          <Route index element={<Navigate to="/gallery" />} />

          <Route path="gallery">
            <Route index element={<GalleryPage />} />

            <Route path=":albumName" element={<GalleryPage />} />
          </Route>

          <Route path="events">
            <Route index element={<EventsPage />} />

            <Route path=":eventId" element={<EventsPage />} />
          </Route>

          <Route
            path="contact"
            element={
              <ContainerCentered>
                <Contact />
              </ContainerCentered>
            }
          />
          <Route
            path="about"
            element={
              <ContainerCentered>
                <AboutUs />
              </ContainerCentered>
            }
          />
        </Route>
      </Routes>

      <Footer />
    </ThemeProvider>
  );
};

export default App;
